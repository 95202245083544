/**
 * Data collected from PubMed on scholarship
 */
export class PubMedData {
    citationCount;
    citationCountMissing;
    rcr;
    articleData;
}
/**
 * Specific article data sourced from PubMed
 */
export class ArticleData {
    uid;
    title;
    volume;
    issue;
    pages;
    source;
    journal;
    language;
    issn;
    pii;
    doi;
    pubdateData;
    articledateData;
    medlinedateData;
    authorList;
    ids;
    constructor(pmid = "") {
        this.uid = pmid;
        this.authorList = [];
        this.ids = {};
    }
    get pubdate() {
        if (this.pubdateData != null) {
            return this.pubdateData.reverseString;
        }
        else if (this.articledateData != null) {
            return this.articledateData.reverseString;
        }
        else if (this.medlinedateData != null) {
            return this.medlinedateData.reverseString;
        }
        else {
            return "";
        }
    }
    get sortpubdate() {
        if (this.pubdateData != null &&
            (this.pubdateData.month || this.articledateData == null)) {
            return this.pubdateData.string;
        }
        else if (this.articledateData != null) {
            return this.articledateData.string;
        }
        else if (this.medlinedateData != null) {
            return this.medlinedateData.string;
        }
        else {
            return "";
        }
    }
}
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
const numToMonths = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
};
const isMonth = (x) => months.some((a) => a === x);
export class PubMedDate {
    constructor(month, date, year) {
        if (numToMonths[parseInt(month)] != null) {
            this.monthNum = month;
            this.month = numToMonths[parseInt(month)];
        }
        else if (month != null) {
            const firstMonth = month.split("-")[0].trim();
            if (isMonth(firstMonth)) {
                this.month = firstMonth;
            }
            const monthIndex = Object.values(numToMonths).indexOf(month.trim());
            if (monthIndex >= 0) {
                this.monthNum = Object.keys(numToMonths)[monthIndex].toString();
            }
        }
        this.date = date || "";
        this.year = year || "";
        const monthString = this.monthNum || this.month || "";
        this.string =
            (monthString == ""
                ? ""
                : monthString +
                    (this.date.trim().length == 0 ? "" : "/" + parseInt(this.date)) +
                    "/") + this.year;
        this.reverseString =
            this.year +
                " " +
                this.month +
                (this.date != "" ? " " + parseInt(this.date) : ""); // For citation
    }
    month;
    year; // yyyy
    date; // dd
    monthNum;
    string; // monthNum/date/year
    reverseString; // year month date
    type;
}
